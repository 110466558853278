import React, {useCallback, useState} from "react";
import {
	ButtonsBox,
	Modal,
	ModalContainer,
	ModalContent,
	Text,
	Title,
} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import {PrimaryButton} from "components";
import {hideAcceptTCModal} from "modules/actions/modals";
import {getIsOpenAcceptTCModal} from "modules/selectors";
import styled from "styled-components";
import {Checkbox, FormControlLabel} from "@mui/material";

const FormControlLabelStyled = styled(FormControlLabel)`
	align-self: flex-start;
	margin-bottom: 15px;
`;

const ReactivateTitle = styled(Title)`
	line-height: inherit;
`;

const Description = styled(Text)`
	margin-bottom: 10px;
`;

const ContinueButton = styled(PrimaryButton)`
	background-color: #001fd6;
`;

const Label = styled.p`
	padding-left: 3px;
	font-size: 14px;
	color: #001fd6;
	text-align: left;
`;

const ContinueLink = styled.a`
	font-size: 14px;
	text-decoration: underline;
	color: #343232;
`;

const ContinueButtonsBox = styled(ButtonsBox)`
	margin-top: 15px;
`;

const CheckoutLabel: React.FC = () => {
	return (
		<Label>
			I have read and accepted the Super Rugby Pacific{" "}
			<ContinueLink
				href="https://tipping.super.rugby/help/terms"
				target="_blank"
				rel="noreferrer">
				Terms and Conditions
			</ContinueLink>{" "}
			and{" "}
			<ContinueLink
				href="https://www.allblacks.com/privacy-policy/"
				target="_blank"
				rel="noreferrer">
				NZRC Privacy Statement
			</ContinueLink>
			*
		</Label>
	);
};

const checkBoxStyle = {
	"&.Mui-checked": {
		color: "#001fd6",
	},
};

export const AcceptTCModal: React.FC = () => {
	const [form, updateForm] = useState({terms: false});
	const [isDisabled, setDisableButton] = useState(true);

	const dispatch = useDispatch();
	const open = useSelector(getIsOpenAcceptTCModal);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = e.currentTarget;

		if (name === "terms") {
			setDisableButton(!checked);
		}

		updateForm({
			...form,
			[name]: checked,
		});
	};

	const closeHandler = useCallback(() => {
		dispatch(hideAcceptTCModal({acceptTerms: 1}));
	}, [dispatch]);

	if (!open) return null;

	return (
		<ModalContainer>
			<Modal>
				<ModalContent>
					<ReactivateTitle>WE HAVE UPDATED OUR TERMS AND CONDITIONS</ReactivateTitle>
					<Description>
						In order to continue you are required to read and accept the updated terms
						and conditions.
					</Description>
					<FormControlLabelStyled
						control={
							<Checkbox name="terms" onChange={handleOnChange} sx={checkBoxStyle} />
						}
						label={<CheckoutLabel />}
					/>
					<ContinueButtonsBox>
						<ContinueButton disabled={isDisabled} onClick={closeHandler}>
							CONTINUE{" "}
						</ContinueButton>
					</ContinueButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
