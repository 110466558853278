import {get} from "lodash";
import {ILeague} from "modules/reducers";
import {SectionType, ShareType} from "modules/enums";
import * as Sentry from "@sentry/react";

export interface IShare {
	sc: ShareType;
	section: SectionType;
	message?: string;
	code?: string;
	t?: string;
	round?: number;
	league_data?: Partial<ILeague> | null;
	user_id?: number;
}

export interface IShareObject {
	t: string;
	section: SectionType;
	sc: ShareType;
	leagueId?: number;
	user_id?: number;
	code?: string;
}

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
const last_four_number = -4;

export const share_messages = {
	league: (code?: string) => {
		return `You think you can beat me in tipping this Super Rugby Pacific season? I’ll prove you wrong! Come join my league now. The league pin is ${code!}`;
	},
	"invite-friends": () => {
		return `Pick the team you think will win each match this Super Rugby Pacific season and you could win some epic prizes. It’s free and easy to join!`;
	},
};

export const share = (data: IShare) => {
	if (data.sc === ShareType.MOBILE) {
		return shareNavigator(data);
	}

	if (data.sc === ShareType.TW) {
		return shareTwitter(data);
	}

	if (data.sc === ShareType.FB) {
		return shareFB(data);
	}
};

export const shareNavigator = async (data: IShare) => {
	const {league_data, section, sc} = data;

	const object: IShareObject = {
		t: getTimestamp(),
		section,
		sc,
	};

	if (league_data) {
		object.leagueId = league_data.id;
	}

	try {
		const url = getLinkForShare(object, section);
		const text = share_messages[section]();

		const shareData = {
			url,
			text,
		};

		await navigator.share(shareData);
	} catch (err) {
		Sentry.captureException(err);
		if (err instanceof Error) {
			console.log(err.message);
		}
	}
};

const getTimestamp = () => Date.now().toString().substr(last_four_number);
const getEncodedJson = (object: IShareObject) => btoa(JSON.stringify(object));

const getLinkForShare = (object: IShareObject, type: SectionType) => {
	const link = `${FB_SHARE_URL}${type}`;
	const sections: {[key: string]: () => string} = {
		[SectionType.INVITE_FRIENDS]: () => `${link}?v=${getTimestamp()}`,
		[SectionType.LEAGUE]: () => `${link}/${getEncodedJson(object)}?v=${getTimestamp()}`,
	};
	return sections[type]();
};

export const shareFB = (data: IShare) => {
	const {league_data, section, sc} = data;
	const FB_API = get(window, "FB");
	const object: IShareObject = {
		t: getTimestamp(),
		section,
		sc,
	};

	if (league_data) {
		object.leagueId = league_data.id;
	}

	//const isInvites = section === "league";

	const isInvites = false;

	FB_API.ui(
		{
			method: isInvites ? "send" : "share",
			display: "popup",
			[isInvites ? "link" : "href"]: getLinkForShare(object, section),
		},
		(response: unknown) => {
			console.log(response);
		}
	);
};

export const shareTwitter = (data: IShare) => {
	console.log("here", data);
	const {league_data, section, sc} = data;
	const object: IShareObject = {
		t: getTimestamp(),
		section,
		sc,
	};

	if (league_data) {
		object.leagueId = league_data.id;
	}

	const href = getLinkForShare(object, section);
	const text = share_messages[section](league_data?.code);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(text),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};
