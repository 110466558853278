import React, {useRef, Fragment, useCallback, useState, useEffect} from "react";
import {ButtonsBox, Modal, ModalContainer, ModalContent, Text, Title} from "./common";
import {PrimaryButton, FormCheckoutLabel, FormCheckbox} from "components";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {ReactComponent as CheckedCheckbox} from "assets/images/checkedCheckbox.svg";
import {ReactComponent as Checkbox} from "assets/images/checkbox.svg";
import {useDispatch, useSelector} from "react-redux";
import {hideTnCModal} from "modules/actions/modals";
import {getIsOpenTnCModal} from "modules/selectors";

const TermsText = styled(Text)`
	margin-top: 1rem;
	margin-left: 10px;
`;

const TermsLink = styled.a`
	text-decoration: underline;
	color: #343232;
`;

export const TermsModal: React.FC = () => {
	// const [isOpenModal, setIsOpenModal] = useState(true);
	const [isChecked, setIsChecked] = useState(false);
	const [disableButton, setDisableButton] = useState(true);

	const dispatch = useDispatch();
	const ref = useRef(null);
	const open = useSelector(getIsOpenTnCModal);

	const closeHandler = useCallback(() => {
		dispatch(hideTnCModal());
	}, [dispatch]);

	useEffect(() => closeHandler, [closeHandler]);

	// useClickOutside(ref, closeHandler, !open);

	const handleOnChange = () => {
		setIsChecked(!isChecked);
		setDisableButton(!disableButton);
		console.log("Change this");
	};

	// const open = isOpenModal;

	if (!open) return null;

	return (
		<ModalContainer>
			<Modal ref={ref}>
				<ModalContent>
					<Fragment>
						<Title>Terms and Condition</Title>
						<p>(updated)</p>
						<TermsText>
							<p>Super Rugby Pacific terms and conditions have been updated </p>
						</TermsText>
						<FormCheckoutLabel>
							<FormCheckbox
								name="Terms and condition"
								icon={<Checkbox />}
								checkedIcon={<CheckedCheckbox />}
								checked={isChecked}
								onChange={handleOnChange}
							/>
							I have read and accepted the Super Rugby Pacific{" "}
							<NavLink to="/help/terms" target="_blank">
								Terms and Conditions
							</NavLink>{" "}
							and{" "}
							<TermsLink
								href="https://www.allblacks.com/privacy-policy/"
								target="_blank"
								rel="noreferrer">
								NZRC Privacy Statement
							</TermsLink>
						</FormCheckoutLabel>

						{/* <Exist when={errorPlace === "terms"}>
                            <ErrorMessage>{error}</ErrorMessage>
                        </Exist> */}
					</Fragment>
					<ButtonsBox>
						<PrimaryButton disabled={disableButton} onClick={closeHandler}>
							OK{" "}
						</PrimaryButton>
					</ButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
