import {call, put} from "typed-redux-saga";
import {Api, ApiError} from "modules/utils";
import {
	deleteLeague,
	deleteLeagueFailed,
	deleteLeagueSuccess,
	deleteLeagueUser,
	deleteLeagueUserFailed,
	deleteLeagueUserSuccess,
	getLeagueRankings,
	getLeagueRankingsFailed,
	getLeagueUsers,
	getLeagueUsersFailed,
	getLeagueUsersSuccess,
	inviteUsersToLeague,
	inviteUsersToLeagueFailed,
	inviteUsersToLeagueSuccess,
	leaveLeague,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	showGlobalError,
	updateLeague,
	updateLeagueFailed,
	updateLeagueSuccess,
	getLeagueRankingsSuccess,
	clearLeagues,
} from "modules/actions";
import {getLeague, getLeagueFailed, getLeagueSuccess} from "modules/actions/league";
import {showInfoModal} from "modules/actions/modals";

export const getLeagueSaga = function* ({payload}: ReturnType<typeof getLeague>) {
	try {
		const result = yield* call(Api.League.get, payload);

		yield* put(getLeagueSuccess(result.success));
	} catch (err) {
		yield* put(getLeagueFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const getLeagueRankingsSaga = function* ({payload}: ReturnType<typeof getLeagueRankings>) {
	try {
		const result = yield* call(Api.League.rankings, payload);

		yield* put(getLeagueRankingsSuccess(result.success));
	} catch (err) {
		yield* put(getLeagueRankingsFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const getLeagueUsersSaga = function* ({payload}: ReturnType<typeof getLeagueUsers>) {
	try {
		const result = yield* call(Api.League.getUsers, payload);

		yield* put(getLeagueUsersSuccess(result.success));
	} catch (err) {
		yield* put(getLeagueUsersFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const updateLeagueSaga = function* ({payload}: ReturnType<typeof updateLeague>) {
	try {
		const result = yield* call(Api.League.update, payload);

		yield* put(updateLeagueSuccess(result.success.league));
	} catch (err) {
		yield* put(updateLeagueFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const deleteLeagueUserSaga = function* ({payload}: ReturnType<typeof deleteLeagueUser>) {
	try {
		yield* call(Api.League.removeUser, payload);
		yield* put(deleteLeagueUserSuccess(+payload.userId));
	} catch (err) {
		yield* put(deleteLeagueUserFailed(payload.userId));
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const deleteLeagueSaga = function* ({payload}: ReturnType<typeof deleteLeague>) {
	try {
		yield* call(Api.League.delete, payload);
		yield* put(deleteLeagueSuccess());
	} catch (err) {
		yield* put(deleteLeagueFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const leaveLeagueSaga = function* ({payload}: ReturnType<typeof leaveLeague>) {
	try {
		yield* call(Api.League.leave, payload);
		yield put(clearLeagues());
		yield* put(leaveLeagueSuccess());
	} catch (err) {
		yield* put(leaveLeagueFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const inviteUsersToLeagueSaga = function* ({
	payload,
}: ReturnType<typeof inviteUsersToLeague>) {
	try {
		yield* call(Api.League.invite, payload);

		yield* put(inviteUsersToLeagueSuccess());
		yield* put(showInfoModal({message: "Invites sent successfully"}));
	} catch (err) {
		yield* put(inviteUsersToLeagueFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};
