import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {requestLadderJson} from "modules/actions";
import {getLadder} from "modules/selectors";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import DefaultTableCell, {tableCellClasses} from "@mui/material/TableCell";
import DefaultTableHead from "@mui/material/TableHead";
import DefaultTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DefaultTableContainer, {TableContainerTypeMap} from "@mui/material/TableContainer";
import {OverridableComponent} from "@mui/types";
import {Flexible} from "components/Common";
import {Exist} from "components/Exist";
import {IMAGES_URL} from "modules";
import {SEASON_YEAR} from "modules/constants";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const TableContainer: OverridableComponent<TableContainerTypeMap> = styled(DefaultTableContainer)`
	&& {
		border-radius: 0 0 4px 4px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	}
`;

const Title = styled.div`
	border-radius: 4px 4px 0 0;
	text-align: center;
	padding: 18px 10px;
	font-size: 14px;
	font-weight: 700;
	color: ${({theme}) => theme.text.primary};
	background-color: ${({theme}) => theme.colors.secondary};
`;

const TableCell = styled(DefaultTableCell)`
	&.${tableCellClasses.head} {
		padding: 8px;
		background-color: #435058;
		color: ${({theme}) => theme.text.primary};

		&:nth-child(2) {
			padding-left: 20px;
		}
	}

	&.${tableCellClasses.body} {
		padding: 5px;
	}

	&.${tableCellClasses.head}, &.${tableCellClasses.body} {
		font-family: Kino, sans-serif;
		border-bottom: none;
		font-weight: 400;
		font-size: 10px;

		&:first-child {
			padding-left: 12px;
		}

		&:last-child {
			padding-right: 12px;
		}

		&:nth-child(2) {
			width: 100%;
		}
	}
`;

const TableHead = styled(DefaultTableHead)`
	border-radius: 0;
	padding: 0 4px;
`;

const TeamImage = styled.img`
	width: 30px;
	height: 30px;
	object-fit: cover;
	margin-right: 5px;
`;

const TableRow = styled(DefaultTableRow)`
	border-bottom: 2px solid ${({theme}) => theme.table.body.secondary};
`;

const MockTableRow = styled(TableRow)`
	height: 40px;
`;

export const TeamRankings: React.FC = () => {
	const dispatch = useDispatch();
	const ladder = useSelector(getLadder);

	useEffect(() => {
		dispatch(requestLadderJson());
	}, [dispatch]);

	return (
		<Wrapper>
			<Title>Super Rugby Pacific {SEASON_YEAR} Ladder</Title>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="center">Pos</TableCell>
							<TableCell>Team</TableCell>
							<TableCell align="center">Pld.</TableCell>
							<TableCell align="center">Won</TableCell>
							<TableCell align="center">Pts.</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<Exist when={!!ladder.length}>
							{ladder.map((team, i) => (
								<TableRow key={team.id}>
									<TableCell align="center">{i + 1}</TableCell>
									<TableCell>
										<Flexible>
											<TeamImage
												src={`${IMAGES_URL}squads/${team.id}.png`}
												alt={team.name}
											/>
											<div>{team.name}</div>
										</Flexible>
									</TableCell>
									<TableCell align="center">{team.games}</TableCell>
									<TableCell align="center">{team.win}</TableCell>
									<TableCell align="center">{team.points}</TableCell>
								</TableRow>
							))}
						</Exist>
						<Exist when={!ladder.length}>
							{Array.from({length: 12}).map((_, i) => (
								<MockTableRow key={i} />
							))}
						</Exist>
					</TableBody>
				</Table>
			</TableContainer>
		</Wrapper>
	);
};
