import React from "react";
import styled from "styled-components";
import {Link as DefaultLink} from "react-router-dom";
import {ReactComponent as DefaultGeniusSportsLogo} from "assets/images/geniusSportsLogoWhite.svg";
import {ExternalLink} from "components/Common";
import footerLogo from "assets/images/footerLogo.png";
import {SEASON_YEAR} from "modules/constants";

const FooterContainer = styled.footer`
	padding: 40px 30px 45px;
	background-color: ${({theme}) => theme.colors.primary};

	@media (min-width: 769px) {
		height: 340px;
		padding: 85px 105px 50px 120px;
	}
`;

const Links = styled.div`
	display: flex;
`;

const linkStyles = `
	margin-right: 20px;
	padding: 0 11px;
	font-size: 14px;
	line-height: 22px;
`;

const Link = styled(DefaultLink)`
	${linkStyles};
	border-bottom: 1px solid ${({theme}) => theme.border.primary};
	color: ${({theme}) => theme.text.primary};
`;

const TargetLink = styled.a`
	${linkStyles};
	border-bottom: 1px solid ${({theme}) => theme.border.primary};
	color: ${({theme}) => theme.text.primary};

	@media (max-width: 768px) {
		margin-right: 0;

		&:last-child {
			display: none;
		}
	}
`;

const Flexed = styled.div`
	font-size: 12px;
	margin-top: 30px;

	@media (min-width: 769px) {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-top: 110px;
		font-size: 10px;
	}
`;

const Copyright = styled.div`
	display: flex;
	align-items: flex-end;
	color: ${({theme}) => theme.text.primary};
	margin-bottom: 150px;

	@media (min-width: 769px) {
		margin-bottom: 0;
	}
`;

const Logo = styled.div`
	margin-right: 6px;
	display: none;

	@media (min-width: 769px) {
		display: block;
		img {
			width: 50px;
		}
	}
`;

const CopyrightText = styled.span`
	line-height: 18px;

	@media (min-width: 769px) {
		margin: 0 0 3px 6px;
	}
`;

const CopyrightDesc = styled.span`
	display: block;

	@media (min-width: 769px) {
		display: inline;
		text-transform: uppercase;
	}
`;

const PoweredBy = styled(ExternalLink)`
	display: flex;
	align-items: center;
	font-weight: 700;
	text-transform: uppercase;
	color: ${({theme}) => theme.text.secondary};
`;

const GeniusSportsLogo = styled(DefaultGeniusSportsLogo)`
	margin-left: 10px;
`;

export const Footer: React.FC = () => {
	return (
		<FooterContainer>
			<Links>
				<Link to="/help/terms">Terms & Conditions</Link>
				<TargetLink href="https://super.rugby/general/privacy-policy/" target="_blank">
					Privacy Policy
				</TargetLink>
				<TargetLink
					href="https://super.rugby/superrugby/"
					rel="noreferrer noopener"
					target="_blank">
					Super Rugby Pacific
				</TargetLink>
			</Links>
			<Flexed>
				<Copyright>
					<Logo>
						<img src={footerLogo} alt="logo" />
					</Logo>
					<CopyrightText>
						<CopyrightDesc>THE HOME OF RUGBY IN THE SOUTHERN HEMISPHERE</CopyrightDesc>
						<br />
						<span>© Super Rugby Pacific {SEASON_YEAR}. All rights reserved.</span>
					</CopyrightText>
				</Copyright>
				<PoweredBy href="https://geniussports.com/genius-marketing-suite/genius-games/">
					<span>Powered by:</span>
					<GeniusSportsLogo />
				</PoweredBy>
			</Flexed>
		</FooterContainer>
	);
};
