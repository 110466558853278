import {createAction} from "redux-act";
import {IModal} from "modules/types/modal";

export const showInfoModal = createAction<IModal>();
export const hideInfoModal = createAction();

export const showSaveTipsModal = createAction();
export const hideSaveTipsModal = createAction();

export const showTnCModal = createAction();
export const hideTnCModal = createAction();

export const showReactivateModal = createAction();

export interface IReactivatePayload {
	NZRUOptin: 1 | 0;
	rugbyAuOptin: 1 | 0;
	acceptTerms: 1 | 0;
	clubPolicyOptin?: 1 | 0;
	favoriteSquadId?: number;
}
export const hideReactivateModal = createAction<IReactivatePayload>();

export const showAcceptTCModal = createAction();

export interface IAcceptTCPayload {
	acceptTerms: 1 | 0;
}
export const hideAcceptTCModal = createAction<IAcceptTCPayload>();
