import React, {useEffect} from "react";
import {Navigate, Outlet, RouteProps} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUser, isLoggedIn} from "modules/selectors";
import {useLocation} from "react-router";
import {showReactivateModal} from "modules/actions/modals";

export const PrivateRoute: React.FC<RouteProps> = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const user = useSelector(getUser);
	const loggedIn = useSelector(isLoggedIn);

	useEffect(() => {
		// if (user?.registeredAt === null && user?.isActive === false) {
		if (user?.isActive === false) {
			dispatch(showReactivateModal());
		}
	}, [dispatch, user]);

	// useEffect(() => {
	// 	// if (user?.termsAccepted === null && user?.registeredAt !== null) {
	// 	if (user?.termsAccepted === null) {
	// 		dispatch(showAcceptTCModal());
	// 	}
	// }, [dispatch, user]);

	if (loggedIn) {
		return <Outlet />;
	}

	return <Navigate to="/" state={{from: location}} replace />;
};
