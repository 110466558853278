import {all, call, delay, put, select} from "typed-redux-saga";
import {Api, ApiError} from "modules/utils";
import {
	IPredictionsPayload,
	IRound,
	ITournament,
	postAutoPickFailed,
	postAutoPickSuccess,
	postSavePicksFailed,
	postSavePicksSuccess,
	requestGameDataFailed,
	requestGameDataSuccess,
	requestGameJsonDataFailed,
	requestGameJsonDataSuccess,
	requestPredictionByRoundId,
	requestPredictionByRoundIdFailed,
	requestPredictionByRoundIdSuccess,
	requestRoundStatsJSON,
	requestSnapshotByRoundId,
	requestSnapshotByRoundIdFailed,
	requestSnapshotByRoundIdSuccess,
	showGlobalError,
	updateSavePickButtonState,
} from "modules/actions";
import {
	requestRoundsJSONSaga,
	requestRoundStatsJSONSaga,
	requestSquadsJSONSaga,
} from "modules/sagas/json";
import {
	getActualRoundId,
	getPickSaveButtonState,
	getPredictionsByTournamentId,
} from "modules/selectors";
import {ISagaAction} from "modules/types";
import {SaveButtonType, TournamentStatusType} from "modules/enums";
import {identity, keyBy, omit} from "lodash";
import {showSaveTipsModal} from "modules/actions/modals";

export const requestPredictionByRoundIdSaga = function* ({payload}: ISagaAction<number>) {
	try {
		const result = yield* call(Api.Predictions.predictions, payload);

		yield* put(
			requestPredictionByRoundIdSuccess(keyBy(result.success.predictions, "tournament"))
		);
	} catch (err) {
		yield* put(requestPredictionByRoundIdFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const requestSnapshotByRoundIdSaga = function* ({payload}: ISagaAction<number>) {
	try {
		const result = yield* call(Api.Rankings.gamebar, payload);

		yield* put(requestSnapshotByRoundIdSuccess(result.success));
	} catch (err) {
		yield* put(requestSnapshotByRoundIdFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const requestGameDataSaga = function* ({payload}: ISagaAction<{roundId?: number}>) {
	try {
		const actualRoundId = yield* select(getActualRoundId);
		const {roundId} = payload;

		if (actualRoundId) {
			const round = roundId ? roundId : actualRoundId;
			yield* all([
				call(requestPredictionByRoundIdSaga, requestPredictionByRoundId(round)),
				call(requestSnapshotByRoundIdSaga, requestSnapshotByRoundId(round)),
				call(requestRoundStatsJSONSaga, requestRoundStatsJSON(round)),
			]);
		}

		yield* put(requestGameDataSuccess());
	} catch (err) {
		yield* put(requestGameDataFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const requestGameJsonDataSaga = function* () {
	try {
		yield* all([call(requestRoundsJSONSaga), call(requestSquadsJSONSaga)]);

		yield* put(requestGameJsonDataSuccess());
	} catch (err) {
		yield* put(requestGameJsonDataFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const postAutoPickSaga = function* ({payload}: ISagaAction<IRound>) {
	try {
		const scheduledTournaments = payload.tournaments.filter(
			({status}: ITournament) => status === TournamentStatusType.Scheduled
		);

		const predictions = scheduledTournaments.map((tour) => ({
			tournament: tour.id,
		}));

		/**
		 * filter out users predictions
		 */
		const userPredections = yield* select(getPredictionsByTournamentId);
		const filteredPredictions = predictions.filter((prediction) => {
			return !userPredections[prediction.tournament];
		});

		const result = yield* call(Api.Predictions.autopick, {predictions: filteredPredictions});

		yield* put(postAutoPickSuccess(keyBy(result.success.predictions, "tournament")));
		yield* put(updateSavePickButtonState(SaveButtonType.Saved));
		yield* put(showSaveTipsModal());
		yield* delay(3000);
		const currentButtonState = yield* select(getPickSaveButtonState);
		if (currentButtonState === SaveButtonType.Saved) {
			yield* put(updateSavePickButtonState(SaveButtonType.Disabled));
		}
	} catch (err) {
		yield* put(postAutoPickFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};
export const postSavePicksSaga = function* ({payload}: ISagaAction<IRound>) {
	try {
		const scheduledTournaments = payload.tournaments.filter(
			({status}: ITournament) => status === TournamentStatusType.Scheduled
		);
		const predictionsById = yield* select(getPredictionsByTournamentId);

		const predictions = scheduledTournaments
			.map((tour) => {
				const prediction = predictionsById[tour.id];

				return prediction
					? {
							...omit(prediction, "isCorrect"),
					  }
					: undefined;
			})
			.filter(identity) as IPredictionsPayload["predictions"];

		if (!predictions.length) {
			return;
		}

		const result = yield* call(Api.Predictions.predictions_list, {predictions});

		yield* put(postSavePicksSuccess(keyBy(result.success.predictions, "tournament")));
		yield* put(updateSavePickButtonState(SaveButtonType.Saved));
		yield* put(showSaveTipsModal());
		yield* delay(3000);
		const currentButtonState = yield* select(getPickSaveButtonState);
		if (currentButtonState === SaveButtonType.Saved) {
			yield* put(updateSavePickButtonState(SaveButtonType.Disabled));
		}
	} catch (err) {
		yield* put(postSavePicksFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};
