import React, {useRef} from "react";
import {ButtonsBox, CloseButton, Img, Modal, ModalContainer, ModalContent, Text} from "./common";
import {PrimaryButton, WarningButton} from "components";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import alertIcon from "assets/images/icons/alert.png";
//import {useClickOutside} from "modules/utils/hooks/useClickOutside";

interface IDeleteLeagueModal {
	open: boolean;
	text: string;
	onSubmit: () => void;
	onClose: () => void;
}

export const LeagueModal: React.FC<IDeleteLeagueModal> = ({open, onClose, onSubmit, text}) => {
	const ref = useRef(null);
	//remove function useClickOutside its now acting as expected
	//useClickOutside(ref, onClose, !open);

	if (!open) return null;

	return (
		<ModalContainer>
			<Modal ref={ref}>
				<CloseButton id="btn-close-error-modal" onClick={onClose}>
					<CloseIcon />
				</CloseButton>
				<ModalContent>
					<Img src={alertIcon} alt="" />
					<Text>{text}</Text>
					<ButtonsBox>
						<PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
						<WarningButton onClick={onSubmit}>OK</WarningButton>
					</ButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
