import React, {useCallback, useState} from "react";
import {
	ButtonsBox,
	Modal,
	ModalContainer,
	ModalContent,
	Text,
	Title,
} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import {FormSelect, PrimaryButton} from "components";
import {hideReactivateModal} from "modules/actions/modals";
import {getIsOpenReactivateModal, getSquads} from "modules/selectors";
import styled from "styled-components";
import {Checkbox, FormControlLabel, MenuItem, SelectChangeEvent} from "@mui/material";

const FormControlLabelStyled = styled(FormControlLabel)`
	align-self: flex-start;
	margin-bottom: 15px;
`;

const ReactivateTitle = styled(Title)`
	line-height: inherit;
`;

const Description = styled(Text)`
	margin-bottom: 10px;
`;

const ReactivateButton = styled(PrimaryButton)`
	background-color: #001fd6;
`;

const Label = styled.p`
	padding-left: 3px;
	font-size: 14px;
	// color: #001fd6;
	text-align: left;
	font-family: "Proxima Nova", sans-serif;
`;

const ReactivateLink = styled.a`
	font-size: 14px;
	text-decoration: underline;
	color: #343232;
`;

const ReactivateButtonsBox = styled(ButtonsBox)`
	margin-top: 15px;
`;

const CheckoutLabel: React.FC = () => {
	return (
		<Label>
			I have read and accepted the Super Rugby Pacific{" "}
			<ReactivateLink
				href="https://tipping.super.rugby/help/terms"
				target="_blank"
				rel="noreferrer">
				Terms and Conditions
			</ReactivateLink>{" "}
			and{" "}
			<ReactivateLink
				href="https://www.allblacks.com/privacy-policy/"
				target="_blank"
				rel="noreferrer">
				NZRC Privacy Statement
			</ReactivateLink>{" "}
			and the{" "}
			<ReactivateLink href="https://australia.rugby/privacy" target="_blank" rel="noreferrer">
				Rugby Australia Privacy Policy
			</ReactivateLink>
			*
		</Label>
	);
};

const CheckboxMarketingLabel = () => {
	return (
		<Label>
			I'd like to hear about special offers, tickets, events, news and more from New Zealand
			Rugby Commercial (NZRC).
		</Label>
	);
};
const CheckboxPrivacyLabel = () => {
	return (
		<Label>
			I would like to receive offers and marketing communications from Rugby Australia.
		</Label>
	);
};

const checkBoxStyle = {
	"&.Mui-checked": {
		color: "#001fd6",
	},
};

export const ReactivateModal: React.FC = () => {
	const [form, updateForm] = useState({
		terms: false,
		NZRUOptin: false,
		rugbyAuOptin: false,
		clubPolicyOptin: false,
		favoriteSquadId: 0,
	});
	const [isDisabled, setDisableButton] = useState(true);
	const squads = useSelector(getSquads);
	const dispatch = useDispatch();
	const open = useSelector(getIsOpenReactivateModal);

	const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
		const value = e.target.value as string;
		updateForm({
			...form,
			favoriteSquadId: parseInt(value, 10),
		});
		if (form.terms) {
			setDisableButton(false);
		}
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = e.currentTarget;

		if (name === "terms" && form.favoriteSquadId !== 0) {
			setDisableButton(!checked);
		}
		updateForm({
			...form,
			[name]: checked,
		});
	};

	const closeHandler = useCallback(() => {
		dispatch(
			hideReactivateModal({
				NZRUOptin: form.NZRUOptin ? 1 : 0,
				rugbyAuOptin: form.rugbyAuOptin ? 1 : 0,
				clubPolicyOptin: form.clubPolicyOptin ? 1 : 0,
				favoriteSquadId: form.favoriteSquadId,
				acceptTerms: 1,
			})
		);
	}, [dispatch, form]);

	if (!open) return null;

	return (
		<ModalContainer>
			<Modal>
				<ModalContent>
					<ReactivateTitle>
						REACTIVATE YOUR SUPER RUGBY PACIFIC TIPPING ACCOUNT
					</ReactivateTitle>
					<Description>
						Welcome back! As you have previously registered for Super Rugby Pacific
						tipping you will be using the same account, please accept the Terms and
						Conditions below to continue.
					</Description>
					<FormControlLabelStyled
						control={
							<Checkbox
								name="NZRUOptin"
								onChange={handleOnChange}
								sx={checkBoxStyle}
							/>
						}
						label={<CheckboxMarketingLabel />}
					/>
					<FormControlLabelStyled
						control={
							<Checkbox
								name="rugbyAuOptin"
								onChange={handleOnChange}
								sx={checkBoxStyle}
							/>
						}
						label={<CheckboxPrivacyLabel />}
					/>
					<FormControlLabelStyled
						control={
							<Checkbox
								name="clubPolicyOptin"
								onChange={handleOnChange}
								sx={checkBoxStyle}
							/>
						}
						label={
							<Label>
								I'd like to hear about special offers, tickets, events, news and
								more from my favourite club in line with its privacy policy.
							</Label>
						}
					/>
					<FormSelect
						id="registration-club-select-label"
						label="Club supported*"
						defaultValue="0"
						name="favoriteSquadId"
						required
						onChange={handleSelectChange}>
						{squads.map(({id, name}) => (
							<MenuItem key={id} value={id}>
								{name}
							</MenuItem>
						))}
					</FormSelect>
					<FormControlLabelStyled
						control={
							<Checkbox name="terms" onChange={handleOnChange} sx={checkBoxStyle} />
						}
						label={<CheckoutLabel />}
					/>
					<ReactivateButtonsBox>
						<ReactivateButton disabled={isDisabled} onClick={closeHandler}>
							REACTIVATE{" "}
						</ReactivateButton>
					</ReactivateButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
