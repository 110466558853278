import {IStore} from "../../types/store";
import {createSelector} from "reselect";
import {filter, find, findLast, isUndefined, keyBy, last, matches, memoize, toPairs} from "lodash";
import {RoundStatusType} from "modules/enums";
import {IRound} from "modules/actions";
import {DateTime} from "luxon";
import {ILeague} from "modules/reducers";

export const getJSONs = (state: IStore) => state.jsons;

export const getRounds = createSelector(getJSONs, (jsons) => jsons.rounds);
export const getRoundsRequestState = createSelector(
	getJSONs,
	({roundsRequestState}) => roundsRequestState
);
export const getRoundStatsById = createSelector(getJSONs, (jsons) => jsons.roundStats);
export const getChecksums = createSelector(getJSONs, (jsons) => jsons.checksums);
export const getHelp = createSelector(getJSONs, (jsons) => jsons.help);
export const getFAQs = createSelector(getJSONs, (jsons) => jsons.faq);
export const getLadder = createSelector(getJSONs, ({ladder}) => ladder);

export const getActualRound = createSelector(getRounds, (rounds) => {
	return (
		find(rounds, ({status}) =>
			[RoundStatusType.Active, RoundStatusType.Scheduled].includes(status)
		) || last(rounds)
	);
});
export const getActiveRound = createSelector(getRounds, (rounds) => {
	return find(rounds, ({status}) => [RoundStatusType.Active].includes(status));
});

export const getLeagueStandingsRounds = createSelector(getRounds, (rounds) => (league: ILeague) => {
	const completeRounds = filter(rounds, ({status}) => status === RoundStatusType.Complete);
	const startRound = filter(rounds, (round) => round.id === league.startRound);

	return completeRounds.length ? completeRounds : startRound;
});

export const getScheduledRounds = createSelector(getRounds, (rounds) =>
	rounds.filter(({status}) => status === RoundStatusType.Scheduled)
);

export const getRoundByIdSelector = createSelector(
	getRounds,
	getActualRound,
	(rounds, actualRound) =>
		memoize((id: number = 0) => {
			return id ? find(rounds, matches({id})) : actualRound;
		})
);

export const getLeagueRound = createSelector(getRounds, (rounds) => (league: ILeague) => {
	return (
		findLast(rounds, ({status}) => [RoundStatusType.Complete].includes(status)) ||
		find(rounds, (round) => round.id === league.startRound)
	);
});

export const getRankingsRound = createSelector(getRounds, (rounds) => {
	return findLast(rounds, ({status}) => [RoundStatusType.Complete].includes(status));
});

export const getRankingsRounds = createSelector(getRounds, (rounds) => {
	return filter(rounds, ({status}) => status === RoundStatusType.Complete);
});

export const getActualRoundId = createSelector(getActualRound, (round) => round?.id);

export const getCountries = createSelector(getJSONs, (jsons) => toPairs(jsons.countries));

export const getSquads = createSelector(getJSONs, (jsons) => jsons.squads);
export const getSquadsById = createSelector(getSquads, (squads) => keyBy(squads, "id"));

export const isOneMatchStartingOrLiveSelector = () =>
	memoize((round?: IRound) => {
		if (!round) {
			return false;
		}
		const now = DateTime.now();
		const roundStart = DateTime.fromISO(round.startDate);
		const isRoundShouldStart = now.diff(roundStart, "minutes").minutes < 1;
		if (round.status === RoundStatusType.Active || isRoundShouldStart) {
			return true;
		}
		const playingMatch = find(round.tournaments, (tournament) => {
			const matchStart = DateTime.fromISO(tournament.date);
			return now.diff(matchStart, "minutes").minutes < 1;
		});

		return !isUndefined(playingMatch);
	});
